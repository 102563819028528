////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

// Libs
// Selection of fonts imported from Google
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

// Fonts
@font-face{
  font-family:"Gore";
  src: url("/assets/fonts/g/regular.eot");
  src: url("/assets/fonts/g/regular.eot#iefix") format("embedded-opentype"),
       url("/assets/fonts/g/regular.woff") format("woff"),
       url("/assets/fonts/g/regular.ttf") format("truetype"),
       url("/assets/fonts/g/regular.svg#svgFontName") format("svg")
}
@font-face{
  font-family:"Gore Rough";
  src:  url("/assets/fonts/g/rough.eot");
  src:  url("/assets/fonts/g/rough.eot#iefix") format("embedded-opentype"),
        url("/assets/fonts/g/rough.woff") format("woff"),
        url("/assets/fonts/g/rough.ttf") format("truetype"),
        url("/assets/fonts/g/rough.svg#svgFontName") format("svg")
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

// Responsive
@media screen and (max-width: 450px) {
  main {
    > h1 {
      font: {
        size: 18px !important;
      }
    }
    .info {
      p {
        padding: 0 5%;
        font: {
          size: 13px !important;
        }
      }
    }
  }
  footer {
    padding: {
      left: 5% !important;
      right: 5% !important;
    }
  }
}

@media screen and (max-width: 750px) {
  nav {
    ul {
      li {
        &:not(#home) {
          &:not(#contact) {
            &:not(#about) {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
// General

html {
  height: 100%;
  width: 100%;
  min: {
    height: 100%;
    width: 100%;
  };
}

// Body
body {
  display: flex;
  color: rgba(255,255,255,1);
  margin: 0;
  padding: 0;
  height: 100%;
  position: relative;
  min: {
    height: 100%;
  }
  flex: {
    direction: column;
  }
  background: {
    color: rgba(0,0,0,1);
    image: url('/assets/images/bg.jpg');
    size: cover;
    repeat: no-repeat;
  }
  font: {
    family: 'Montserrat', sans-serif;
  };

  // Modals
  .modals {
    > * {
      display: none;
    }
    > label {
      &:hover {
        cursor: pointer;
      }
      &:before {
        display: block;
        content: "❌";
        position: fixed;
        top: 0;
        right: 0;
        padding: 10px;
        font: {
          size: 1.75rem;
        }
        z: {
          index: 10000;
        }
      }
      &:after {
        display: block;
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.9);
        z: {
          index: 1000;
        };
      }
    }
    input {
      &[type="checkbox"] {
        display: none;
        &:checked {
          & + label {
            display: block;
            animation: fade_in_show 0.25s;
          }
        }
      }
    }
  }
}

// Links
a {
  color: rgba(255,255,255,1);
  display: inline-block;
  text: {
    decoration: underline;
  };
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

// Header //
header { 
    
  // Vars
  $size: 25px;

  // Styles
  display: flex;
  color: rgba(255,255,255,1);
  align: {
    items: center;
  }
  justify: {
    content: space-between;
  }
  font: {
    size: 9px;
    weight: bold;
    family: 'Montserrat', sans-serif;
  };

  // Logo
  .logo {
    left: 0;
    a {
      padding: 25px;
    }
  }

  // Nav
  nav {

    // All
    > * {
      vertical: {
        align: middle;
      };
    }

    // List
    ul {
      display: inline-block;
      margin: 0;
      padding: 0;
      list: {
        style: none;
      }
      li {
        display: inline-block;
      }
    }

    // Links
    a,
    label {
      display: inline-block;
      padding: 25px 5px;
      text: {
        transform: uppercase;
        decoration: none;
      }
      &.active {
        text-decoration: underline;
        cursor: pointer;
      }
      &:hover {
        text-decoration: underline;
      }
    }

    // Contact
    .contact {
      display: inline-block;
    }

    // Flag
    .locality {
      height: 12px;
      margin: {
        right: 10px;
        left: 5px;
        bottom: 1px;
      };
    }
  }
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
// Main

main {
  flex: 1;
  align: {
    self: center;
  };
  display: flex;
  flex: {
    direction: column;
  }
  align: {
    items: center;
  }
  justify: {
    content: center;
  };

  // H1
  h1 {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    color: rgba(255,255,255,1);
    overflow: hidden;
    font: {
      size: 3.5em;
      family: 'Gore', sans-serif;
    }
    letter: {
      spacing: 1px;
    }
    text: {
      overflow: ellipsis;
      align: center;
    }

    @media (max-width: 900px) and (min-width: 625px) {
      display: block;
      white: {
        space: normal;
      }
    }

    // Strong //
    strong,
    b {
      background: -webkit-linear-gradient(#ffdc0b, #ff300c);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font: {
        family: 'Gore Rough', sans-serif;
      };
    }  
  }

  // Products
  .products {
    display: block;
    text: {
      transform: uppercase;
    }
    font: {
      size: 0.8em;
      weight: bold;
      family: 'Montserrat', sans-serif;
    };
    &:hover {
      a {
        opacity: 0.2;
        &:hover {
          opacity: 1;
        }
      }
    }
    a {
      display: inline-block;
      width: 235px;
      transition: opacity 0.15s ease-in-out;
      padding: 7px 5px 0;
      vertical: {
        align: middle;
      }

      box: {
        sizing: border-box;
      }

      img {
        width: 100%;
        vertical: {
          align: middle;
        }
      }

      &.frontlinefusion {
        padding: {
          left: 15px;
          right: 15px;
        };
      }

      @media (max-width: 900px) {
        display: block;
        width: 50%;
        margin: {
          left: auto;
          right: auto;
        }
      }

    }
  }

  // Info
  .info {
    display: block;
    text: {
      align: center;
    }
    margin: {
      top: 5vh;
    }
    p {
      font: {
        size: 14px;
        family: 'Arial', Helvetica, sans-serif;
      }
      line: {
        height: 0.8em;
      };
    }  
  }

  // TrustPilot
  .trustpilot-widget {
    margin: {
      top: 15px;
    };
  }
  
  // About
  .about {
    h1 {
      font: {
        size: 4em;
      }
    }
    a {
      font: {
        weight: bold;
      }
      text: {
        decoration: underline;
      };
    }  
  }

  // Contact
  .contact {
    h1 {
      font: {
        size: 4em;
      }
    }
    a {
      text: {
        decoration: underline;
      }
    }
    text: {
      align: center;
    }
    h1 {
      margin: 0 0 5px;
      text: {
        transform: uppercase;
      }
    }
    .intro {
      display: block;
      margin: {
        bottom: 5px;
      }
    }
    > form {
      position: absolute;
      top: 50%;
      left: 50%;
      color: rgba(255,255,255,1);
      transform: translateX(-50%) translateY(-50%);
      z: {
        index: 10000;
      }
      .g-recaptcha {
        display: inline-block;
        margin: 5px auto 0;
        text: {
          align: center;
        }
      }
      > input,
      > textarea {
        display: block;
        padding: 15px;
        border: 0;
        width: 100%;
        border: {
          radius: 5px;
        }
        box: {
          sizing: border-box;
        }
        & ~ input,
        & ~ textarea {
          margin: {
            top: 2px;
          }
        }
        &:hover,
        &:active,
        &:focus {
          outline: none;
          border: none;
        }
        &[type="submit"] {
          color: rgba(255,255,255,1);
          background: linear-gradient(#ffdc0b, #ff300c);
          transition: background 0.15s ease-in-out;
          box: {
            shadow: 0 0 3px #ff300c;
          }
          &:hover {
            cursor: pointer;
            text: {
              decoration: underline;
            };
          }
        }
      }
    }
  }
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
// Footer

footer {
  padding: 0 0 15px 0;
  text: {
    align: center;
    transform: uppercase;
  }
  font: {
    family: 'Montserrat', sans-serif;
  };
  a {
    text: {
      decoration: underline;
    }
    &.linkedin {
      height: 20px !important;
      margin: {
        bottom: 0 !important;
      };
    }
  }
  span {
    display: block;
    font: {
      size: 10px;
      weight: 600;
    };
  }
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////